import React from 'react';
import theme from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

export default function wrapWithProvider({ element }) {
  return <>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {element}
    </ThemeProvider>
  </>
}
