import { createTheme, responsiveFontSizes } from '@mui/material';

let theme = createTheme({
  palette: {
    primary: {
      main: '#E30613',
      light: '#ff563f',
      dark: '#a80000'
    },
    secondary: {
      main: '#00778B',
      light: '#599ef3',
      dark: '#00468f'
    },
    hvgray: {
      main: '#7f7f7f',
      dark: '#404040',
      light: '#cacbcc'
    },
    white: {
      main: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
        'Lato',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    h1: {
      fontFamily: 'Oswald',
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: '2rem'
    },
    h2: {
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '1.75rem'
    },
    h3: {
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: 1.5
    },
    h4: {
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '1rem'
    },
    h5: {
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h6: {
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    subtitle: {
      fontFamily: 'Oswald',
      fontWeight: '500'
    }
  },
  
});

theme = createTheme({
  ...theme, 
  components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 0,
            boxShadow: 'none'
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            "&.Mui-expanded": {
              color: theme.palette.primary.main,
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: theme.palette.primary.main
              }
            }
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Oswald'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'Oswald'
          }
        }
      }
    }
})

theme = responsiveFontSizes(theme);

export default theme;
