module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HIGHVOLT KOLLOQUIUM 2023","short_name":"HIGHVOLT KOLLOQUIUM","start_url":"/","background_color":"#E30613","theme_color":"#E30613","display":"standalone","icon":"src/images/icon-whitespace.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/@iostindex/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/info/","proceedings/","/program/","/proceeding/*","/schedule/*"],"workboxConfig":{"globPatterns":["**/*{.html,.js,.json,.webp,.webmanifest,.woff,.woff2,.ttf,.eot,.css,.mjs,.svg,.ico,.png,.jpg,.jpeg,.gif,.xml,.txt,.map}"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
