import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/600.css";
import "@fontsource/oswald/700.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import "./src/styles/global.css"

import wrapWithProvider from './src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const onServiceWorkerUpdateReady = () => {
  console.info('update service worker');
  window.location.reload(true)
}